<template>
  <div>
    <Navigation nameColor="rgba(51, 51, 51, 1)"/>
    <div class="bg_box">
      <div class="nav_box">
        <div class="null_box"></div>

        <img src="@/assets/wordOp/title.png" alt="">
        <el-tabs style="margin: 2rem 0;" v-loading.fullscreen.lock="fullscreenLoading" @tab-click="handleClick">
          <el-tab-pane :label="item" v-for="(item, index) in navList" :key="index"></el-tab-pane>
        </el-tabs>
        <!-- 二级菜单 -->
        <!-- 全部 -->
        <ul class="flex list" v-show="tabShow == '全部'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menuAll" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <span id="free" v-show="item.free != ''"></span> -->
          </li>
        </ul>
        <!-- 简介创作 -->
        <ul class="flex list" v-show="tabShow == '简介创作'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu1" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <span id="free" v-show="item.free != ''"></span> -->
          </li>
        </ul>
        <!-- 文稿创作 -->
        <ul class="flex list" v-show="tabShow == '文稿创作'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu2" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
          </li>
        </ul>
        <!-- 短语创作 -->
        <ul class="flex list" v-show="tabShow == '短语创作'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu3" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <span id="free" v-show="item.free != ''"></span> -->

          </li>
        </ul>
        <!-- 文稿提炼 -->
        <ul class="flex list" v-show="tabShow == '文稿提炼'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu4" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>
        <!-- 脚本创作 -->
        <ul class="flex list" v-show="tabShow == '脚本创作'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu5" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>
        <!-- AI图画 -->
        <ul class="flex list" v-show="tabShow == 'AI文书'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu7" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>

      </div>
    </div>
    <footerBot/>
  </div>
</template>

<script>
export default {
  name: '',
  mounted() {
    this.menuAll.forEach(item => {
      // 简介创作
      if (item.id == 0 || item.id == 5 || item.id == 10) {
        this.menu1.push(item)
        // 文稿创作
      } else if (item.id == 4 || item.id == 7 || item.id == 14 || item.id == 15 || item.id == 16 || item.id == 17 || item.id == 18 || item.id == 19 || item.id == 20 || item.id == 21 || item.id == 22 || item.id == 24 || item.id == 26 || item.id == 27) {
        this.menu2.push(item)
        // 短语创作
      } else if (item.id == 1 || item.id == 8 || item.id == 23 || item.id == 25) {
        this.menu3.push(item)
        // 文稿提炼
      } else if (item.id == 2 || item.id == 9 || item.id == 12) {
        this.menu4.push(item)
        // 脚本创作
      } else if (item.id == 6 || item.id == 11) {
        this.menu5.push(item)
      } else if (item.id == 28 || item.id == 29 || item.id == 30) {
        this.menu7.push(item)
      }
    })
  },
  data() {
    return {
      navList: [
        '全部',
        '简介创作',
        '文稿创作',
        '短语创作',
        '文稿提炼',
        '脚本创作',
        'AI文书',
        // 'AI绘画',
        // 'AI图画'
      ],

      menuAll: [
        {
          id: 0,
          imgUrl: require('@/assets/wordOp/0.png'),
          title: '个人简介',
          free: require('@/assets/wordOp/free.png'),
          path: '/IndividualResume'
        },
        {
          id: 1,
          imgUrl: require('@/assets/wordOp/1.png'),
          title: '广告语',
          free: require('@/assets/wordOp/free.png'),
          path: '/slogan'
        },
        {
          id: 2,
          imgUrl: require('@/assets/wordOp/2.png'),
          title: '文稿润色',
          path: '/manuscriptPolishing'
        },
        // {
        //   id: 3,
        //   imgUrl: require('@/assets/wordOp/4.png'),
        //   title: '错别字修改',
        //   path: '/wrongly',
        // },
        {
          id: 14,
          imgUrl: require('@/assets/wordOp/14.png'),
          title: '一点成诗',
          path: '/verse',
        },
        {
          id: 4,
          imgUrl: require('@/assets/wordOp/4.png'),
          title: '记叙文',
          path: '/narration',
        },
        {
          id: 5,
          imgUrl: require('@/assets/wordOp/5.png'),
          title: '产品简介',
          free: require('@/assets/wordOp/free.png'),
          path: '/productResume',
        },
        {
          id: 6,
          imgUrl: require('@/assets/wordOp/6.png'),
          title: '短视频脚本',
          path: '/shortVideo',
        },
        {
          id: 7,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: '议论文',
          path: '/esay',
        },
        {
          id: 8,
          imgUrl: require('@/assets/wordOp/8.png'),
          title: '祝福语',
          free: require('@/assets/wordOp/free.png'),
          path: '/greeting'
        },
        {
          id: 9,
          imgUrl: require('@/assets/wordOp/9.png'),
          title: '文稿改写',
          path: '/manuscriptRewriting',
        },
        {
          id: 10,
          imgUrl: require('@/assets/wordOp/10.png'),
          title: '企业简介',
          free: require('@/assets/wordOp/free.png'),
          path: '/EnterpriseProfile'
        },
        {
          id: 11,
          imgUrl: require('@/assets/wordOp/11.png'),
          title: '纪录片脚本',
          path: '/documentary',
        },
        {
          id: 12,
          imgUrl: require('@/assets/wordOp/2.png'),
          title: '标题提炼',
          path: '/titleExtraction',
        },
        {
          id: 15,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: '营销软文',
          path: '/article',
        },
        {
          id: 16,
          imgUrl: require('@/assets/wordOp/7.png'),
          title: '说明书',
          path: '/instructions',
        },
        {
          id: 17,
          imgUrl: require('@/assets/wordOp/17.png'),
          title: 'AI小说/歌词',
          path: '/novel',
        },
        {
          id: 18,
          imgUrl: require('@/assets/wordOp/18.png'),
          title: '商业计划书',
          path: '/business',
        },
        {
          id: 19,
          imgUrl: require('@/assets/wordOp/19.png'),
          title: 'PPT内容框架',
          path: '/ppt',
        },
        {
          id: 20,
          imgUrl: require('@/assets/wordOp/20.png'),
          title: 'AI策划方案',
          path: '/activities',
        },
        {
          id: 21,
          imgUrl: require('@/assets/wordOp/21.png'),
          title: 'AI发言稿',
          path: '/speechdraft',
        },
        {
          id: 22,
          imgUrl: require('@/assets/wordOp/22.png'),
          title: 'AI演讲稿',
          path: '/speechtext',
        },
        {
          id: 23,
          imgUrl: require('@/assets/wordOp/23.png'),
          title: '小红书文案',
          path: '/word_redbook',
        },
        {
          id: 24,
          imgUrl: require('@/assets/wordOp/24.png'),
          title: 'AI声明',
          path: '/ai_statement',
        },
        {
          id: 25,
          imgUrl: require('@/assets/wordOp/25.png'),
          title: 'AI商评',
          path: '/business_review',
        },
        {
          id: 26,
          imgUrl: require('@/assets/wordOp/26.png'),
          title: 'AI访谈',
          path: '/interview',
        },
        {
          id: 27,
          imgUrl: require('@/assets/wordOp/27.png'),
          title: 'AI分析报告',
          path: '/analysis_report',
        },
        {
          id: 28,
          imgUrl: require('@/assets/ai/18.png'),
          title: '律师函',
          path: '/lawyer'
        },
        {
          id: 29,
          imgUrl: require('@/assets/ai/21.png'),
          title: '起诉书',
          path: '/prosecute',
        },
        {
          id: 30,
          imgUrl: require('@/assets/ai/20.png'),
          title: '版权侵权告知书',
          path: '/tort'
        },

      ],
      // 简介创作
      menu1: [],
      // 文稿创作
      menu2: [],
      // 短语创作
      menu3: [],
      // 文稿提炼
      menu4: [],
      // 脚本创作
      menu5: [],
      // AI文书
      menu7: [],
      tabShow: '全部',
      fullscreenLoading: false,//加载
    }
  },
  methods: {
    handleClick(tab, event) {
      this.tabShow = tab._props.label
      this.fullscreenLoading = false;
    },
    routePush(id) {
      this.menuAll.forEach(item => {
        if (item.id == id) {
          return this.$router.push(item.path)
        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/wordOp'
</style>